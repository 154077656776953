import Dialog from '@/components/Dialog';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Box from '@/components/Box';
import { TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const DocumentDeleteDialog = ({ onClose, contract }) => {
    const [processing, setProcessing] = useState(false);
    const handleApplicationDelete = () => {
        setProcessing(true);
        router.delete(route('contracts.destroy', { contract: contract.id }), {
            onFinish: () => setProcessing(false),
            onSuccess: () => onClose(),
            preserveScroll: true,
        });
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Contract verwijderen
            </Typography>

            <Typography textAlign={'center'}>Weet je zeker dat je dit contract wilt verwijderen?</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleApplicationDelete}>
                    <TrashIcon width={24} />
                    <span className={'font-bold'}>Contract verwijderen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default DocumentDeleteDialog;
