import { createContext, useContext, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import ErrorDialog from '@/components/dialogs/ErrorDialog.jsx';
import WarningDialog from '@/components/dialogs/WarningDialog.jsx';
import SuccessDialog from '@/components/dialogs/SuccessDialog.jsx';
import ApplicationDeleteDialog from '@/components/dialogs/ApplicationDeleteDialog.jsx';
import ApplicationArchiveDialog from '@/components/dialogs/ApplicationArchiveDialog.jsx';
import LogoutDialog from '@/components/dialogs/LogoutDialog.jsx';
import UserDeleteDialog from '@/components/dialogs/UserDeleteDialog.jsx';
import ApplicationCollateralObjectDeleteDialog from '@/components/dialogs/ApplicationCollateralObjectDeleteDialog.jsx';
import ApplicationCollateralObjectRefreshDialog from '@/components/dialogs/ApplicationCollateralObjectRefreshDialog.jsx';
import CompanyDeleteDialog from '@/components/dialogs/CompanyDeleteDialog.jsx';
import CompanyContactDeleteDialog from '@/components/dialogs/CompanyContactDeleteDialog.jsx';
import InvestorDeleteDialog from '@/components/dialogs/InvestorDeleteDialog.jsx';
import InvestorBondDeleteDialog from '@/components/dialogs/InvestorBondDeleteDialog.jsx';
import DocumentDeleteDialog from '@/components/dialogs/DocumentDeleteDialog.jsx';
import UserImpersonationStartDialog from '@/components/dialogs/UserImpersonationStartDialog.jsx';
import TrancheDeleteDialog from '@/components/dialogs/TrancheDeleteDialog.jsx';
import EntityDeleteDialog from '@/components/dialogs/EntityDeleteDialog.jsx';
import ApplicationCollateralObjectAddToEntityDialog from '@/components/modals/ApplicationCollateralObjectSyncToEntityModal.jsx';
import UserImpersonationStopDialog from '@/components/dialogs/UserImpersonationStopDialog.jsx';
import LabelDeleteDialog from '@/components/dialogs/LabelDeleteDialog.jsx';
import ApplicationCreateContractDialog from '@/components/dialogs/ApplicationCreateContractDialog.jsx';
import ContractSendDialog from '@/components/dialogs/ContractSendDialog.jsx';
import ContractDeleteDialog from '@/components/dialogs/ContractDeleteDialog.jsx';
import ApplicationCollateralObjectUnSyncToEntityDialog from '@/components/dialogs/ApplicationCollateralObjectUnSyncToEntityDialog.jsx';
import CreditReportDeleteDialog from '@/components/dialogs/CreditReportDeleteDialog.jsx';
import CompanyCreditReportFetchDialog from '@/components/dialogs/CompanyCreditReportFetchDialog.jsx';
import ApplicationSourceDeleteDialog from '@/components/dialogs/ApplicationSourceDeleteDialog.jsx';
import UserDetachDialog from '@/components/dialogs/UserDetachDialog.jsx';
import UserResendInviteDialog from '@/components/dialogs/UserResendInviteDialog.jsx';
import ApplicationDuplicateDialog from '@/components/dialogs/ApplicationDuplicateDialog.jsx';
import ApplicationSendToHubspotDialog from '@/components/dialogs/ApplicationSendToHubspotDialog.jsx';
import InvestorSendToHubspotDialog from '@/components/dialogs/InvestorSendToHubspotDialog.jsx';

const Dialogs = {
    'success': SuccessDialog,
    'warning': WarningDialog,
    'error': ErrorDialog,
    'logout': LogoutDialog,
    'application-delete': ApplicationDeleteDialog,
    'application-duplicate': ApplicationDuplicateDialog,
    'source-delete': ApplicationSourceDeleteDialog,
    'application-create-contract': ApplicationCreateContractDialog,
    'application-send-to-hubspot': ApplicationSendToHubspotDialog,
    'application-archive': ApplicationArchiveDialog,
    'application-collateral-object-delete': ApplicationCollateralObjectDeleteDialog,
    'application-collateral-object-refresh': ApplicationCollateralObjectRefreshDialog,
    'application-collateral-object-add-to-entity': ApplicationCollateralObjectAddToEntityDialog,
    'application-collateral-object-delete-to-entity': ApplicationCollateralObjectUnSyncToEntityDialog,
    'company-delete': CompanyDeleteDialog,
    'company-credit-report-fetch': CompanyCreditReportFetchDialog,
    'contact-delete': CompanyContactDeleteDialog,
    'investor-delete': InvestorDeleteDialog,
    'investor-bond-delete': InvestorBondDeleteDialog,
    'investor-send-to-hubspot': InvestorSendToHubspotDialog,
    'user-delete': UserDeleteDialog,
    'user-detach': UserDetachDialog,
    'user-impersonation': UserImpersonationStartDialog,
    'user-impersonation-stop': UserImpersonationStopDialog,
    'document-delete': DocumentDeleteDialog,
    'tranche-delete': TrancheDeleteDialog,
    'entity-delete': EntityDeleteDialog,
    'label-delete': LabelDeleteDialog,
    'credit-report-delete': CreditReportDeleteDialog,
    'contract-send': ContractSendDialog,
    'contract-delete': ContractDeleteDialog,
    'user-resend-invite': UserResendInviteDialog,
};

export const DialogContext = createContext();

export const useDialog = () => {
    const context = useContext(DialogContext);

    return context;
};

export const DialogProvider = ({ presets, children }) => {
    const [active, setActive] = useState([]);

    const openDialog = (id, properties = {}) => {
        let newActive = [...active];

        newActive.push({ id, properties });
        setActive(newActive);
    };

    const closeDialog = (index) => {
        let newActive = [...active];

        newActive.splice(index, 1);
        setActive(newActive);
    };

    const clearDialogs = () => {
        setActive([]);
    };

    return (
        <DialogContext.Provider value={{ openDialog, closeDialog, clearDialogs }}>
            <AnimatePresence>
                {active.map((dialog, index) => {
                    const Dialog = Dialogs[dialog.id];

                    return <Dialog key={`dialog-${index}`} presets={presets} {...dialog.properties} onClose={() => closeDialog(index)} />;
                })}
            </AnimatePresence>

            {children}
        </DialogContext.Provider>
    );
};
