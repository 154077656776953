import Modal from '@/components/Modal';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import { useForm } from '@inertiajs/react';
import Box from '@/components/Box';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import CompanyForm from '@/components/forms/CompanyForm';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';

const CompanyEditorModal = ({ onClose, company, presets }) => {
    const defaultValues = { ...company };
    const form = useForm(defaultValues);

    const handleApplicationEditor = (event) => {
        event.preventDefault();
        if (company) {
            form.submit('put', route('companies.update', { company: company.id }), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        } else {
            form.submit('post', route('companies.store'), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        }
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Bedrijf {company ? 'wijzigen' : 'toevoegen'}</Typography>

            <Typography>Vul onderstaand de gegevens van een bedrijf in.</Typography>

            <CompanyForm className={'mt-5'} form={form} presets={presets} onSubmit={handleApplicationEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handleApplicationEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Bedrijf {company ? 'wijzigen' : 'toevoegen'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default CompanyEditorModal;
