import styled from 'styled-components';
import Box from '../Box.jsx';
import Rive from '@rive-app/react-canvas';
import { layout, position } from 'styled-system';
import { AnimatePresence, motion } from 'framer-motion';
import * as Motioner from '../../utils/MotionerUtil.js';
import asset from '@/utils/VaporUtil.js';

const LoadingOverlay = ({ loading = false, ...properties }) => (
    <AnimatePresence>
        {loading && (
            <Component {...Motioner.loader} {...properties}>
                <Box height={80} width={80}>
                    <Rive src={asset('assets/rivs/loading.riv')} />
                </Box>
            </Component>
        )}
    </AnimatePresence>
);

const Component = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    z-index: 20;
    ${position}
    ${layout}
`;

export default LoadingOverlay;
