import Dialog from '@/components/Dialog';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Box from '@/components/Box';
import { ArrowRightIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const UserImpersonationStartDialog = ({ onClose, user }) => {
    const [processing, setProcessing] = useState(false);
    const handleImpersonation = () => {
        setProcessing(true);

        router.visit(route('impersonate', user.id), {
            onFinish: () => setProcessing(false),
            onSuccess: () => onClose(),
        });
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Inloggen als andere gebruiker
            </Typography>

            <Typography textAlign={'center'}>Weet je zeker dat je wilt inloggen als deze gebruiker?</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleImpersonation}>
                    <span className={'font-bold'}>Inloggen als gebruiker</span>
                    <ArrowRightIcon width={24} />
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default UserImpersonationStartDialog;
