import Drawer from '@/components/Drawer';
import Typography from '@/components/Typography';
import Box from '@/components/Box';
import * as Formatter from '@/utils/FormatterUtil.js';
import Error from '@/components/Error.jsx';
import React from 'react';

const ActivitiesDrawer = ({ onClose, activities }) => {
    return (
        <Drawer onClose={onClose}>
            {activities?.length > 0 ? (
                <>
                    <Typography className={'text-xl font-bold'}>Activiteiten</Typography>

                    <Box mt={40}>
                        {activities?.map((activity, index) => (
                            <Box key={index} mb={24} p={24} width={'100%'} backgroundColor={'background.variant'} borderRadius={'.5rem'}>
                                <Typography>{activity.description}</Typography>

                                <Typography color={'grey.9'} mt={16}>
                                    {Formatter.dateLocalised(activity.created_at)}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </>
            ) : (
                <Error height={'100%'} title={'Niet gevonden!'} description={'Er zijn geen activiteiten gevonden.'} />
            )}
        </Drawer>
    );
};

export default ActivitiesDrawer;
