import Box from './Box';
import Typography from './Typography';
import { CubeTransparentIcon } from '@heroicons/react/24/outline';

const Error = ({ title, description, ...properties }) => (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} opacity={0.4} {...properties}>
        <CubeTransparentIcon height={200} width={200} />

        <Typography className={'text-xl font-bold'} mt={40}>
            {title}
        </Typography>

        <Typography>{description}</Typography>
    </Box>
);

export default Error;
