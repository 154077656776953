import Modal from '@/components/Modal';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import { useForm } from '@inertiajs/react';
import Box from '@/components/Box';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';
import ApplicationSourceForm from '@/components/forms/ApplicationSourceForm';

const ApplicationSourceEditorModal = ({ onClose, presets, source, hidden }) => {
    const initialValues = {
        ...source,
    };
    const form = useForm(initialValues);

    const handlePartnerEditor = (event) => {
        event.preventDefault();

        if (source?.id) {
            form.submit(
                'put',
                route('settings.application-sources.update', {
                    application_source: source.id,
                }),
                {
                    onSuccess: () => onClose(),
                    preserveScroll: true,
                },
            );
        } else {
            form.submit('post', route('settings.application-sources.store'), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        }
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Partner widget {source ? 'wijzigen' : 'toevoegen'}</Typography>

            <Typography>Vul onderstaand de gegevens voor een partner widget in.</Typography>

            <ApplicationSourceForm className={'mt-5'} form={form} presets={presets} hidden={hidden} onSubmit={handlePartnerEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handlePartnerEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Partner widget {source ? 'wijzigen' : 'toevoegen'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default ApplicationSourceEditorModal;
