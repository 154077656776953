import Modal from '@/components/Modal';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import { useForm } from '@inertiajs/react';
import Box from '@/components/Box';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import InvestorAddressForm from '@/components/forms/InvestorAddressForm';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';

const InvestorAddressEditorModal = ({ onClose, investor, presets }) => {
    const defaultValues = { ...investor };
    const form = useForm(defaultValues);

    const handleAddressEditor = (event) => {
        event.preventDefault();
        form.submit('put', route('investors.update', { investor: investor.id }), {
            onSuccess: () => onClose(),
            preserveScroll: true,
        });
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Adres wijzigen</Typography>

            <Typography>Vul onderstaand de gegevens van een adres in.</Typography>

            <InvestorAddressForm className={'mt-5'} form={form} presets={presets} onSubmit={handleAddressEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handleAddressEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Adres wijzigen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default InvestorAddressEditorModal;
