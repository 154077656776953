import Dialog from '@/components/Dialog';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Box from '@/components/Box';
import { DocumentPlusIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const ApplicationCreateContractDialog = ({ onClose, application }) => {
    const [processing, setProcessing] = useState(false);

    const handleCreateContract = () => {
        setProcessing(true);

        router.post(route('applications.create-contract', { application: application.id }), null, {
            onSuccess: () => onClose(),
            onFinish: setProcessing(false),
            preserveScroll: true,
        });
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Contract aanmaken
            </Typography>

            <Typography textAlign={'center'}>Weet je zeker dat je een contract wilt aanmaken voor deze aanvraag?</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleCreateContract}>
                    <DocumentPlusIcon width={24} />
                    <span className={'font-bold'}>Contract aanmaken</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default ApplicationCreateContractDialog;
