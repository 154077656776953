import TextField from '../fields/TextField';
import Typography from '../Typography';
import Box from '../Box';
import { useMemo } from 'react';

const CompanyForm = ({ form, onSubmit, ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>Naam*</Typography>

                <TextField name={'name'} type={'text'} value={data?.name} error={errors?.name} onChange={handleFormChange} />

                {errors?.name && <Typography color={'error.base'}>{errors.name}</Typography>}
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography mt={24}>KvK-nummer*</Typography>

                <TextField name={'registration_number'} type={'text'} value={data?.registration_number} error={errors?.registration_number} onChange={handleFormChange} />

                {errors?.registration_number && <Typography color={'error.base'}>{errors.registration_number}</Typography>}
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default CompanyForm;
