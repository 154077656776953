import { useState, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import Spinner from '@/components/Spinner.jsx';
import { useRipple } from '@/hooks/useRipple.jsx';

const classNames = {
    variants: {
        primary: 'bg-primary-500 hover:brightness-110',
        secondary: 'bg-[#F9FAFC] border border-[#B7B7B7] hover:bg-[#29302C] hover:text-white hover:border-[#29302C]',
        black: 'bg-black text-white hover:brightness-150',
        white: 'bg-white text-black border border-[#B7B7B7] hover:bg-[#29302C] hover:text-white hover:border-[#29302C]',
    },
    sizes: {
        small: 'h-12 min-h-12 px-3 rounded-md',
        normal: 'h-14 min-h-14 px-6 rounded-lg',
    },
};

const Button = ({ children, className, variant = 'primary', size = 'normal', disabled = false, loading = false, onClick, ...properties }) => {
    const buttonRef = useRef(null);
    const ripple = useRipple();

    const handleClick = useCallback(
        (event) => {
            ripple.create(event);
            if (onClick) {
                onClick(event);
            }
        },
        [onClick, ripple],
    );

    return (
        <motion.button
            ref={buttonRef}
            className={twMerge(`relative w-fit font-bold ${classNames?.variants?.[variant]} ${classNames?.sizes?.[size]} ${disabled || loading ? 'pointer-events-none opacity-75' : ''} transition-all`, className)}
            onClick={handleClick}
            disabled={disabled || loading}
            whileTap={{ scale: 0.98 }}
            type={'button'}
            {...properties}
        >
            <div className={`flex items-center justify-center gap-2 whitespace-nowrap ${loading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-200`}>{children}</div>

            <AnimatePresence>
                {loading && (
                    <motion.div key='spinner' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='bg-inherit absolute inset-0 flex items-center justify-center'>
                        <Spinner />
                    </motion.div>
                )}
            </AnimatePresence>

            {ripple.render()}
        </motion.button>
    );
};

export default Button;
