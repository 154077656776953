import TextField from '../fields/TextField';
import Typography from '../Typography';
import Box from '../Box';
import { useMemo } from 'react';

const InvestorAddressForm = ({ form, onSubmit, ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event, key) => {
        const target = event.target;
        const { name, value } = target;

        if (key) {
            form.setData({ ...data, [key]: { ...data?.[key], [name]: value } });
        } else {
            form.setData({ ...data, [name]: value });
        }
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Straat*</Typography>

                    <TextField name={'street'} type={'text'} value={data?.address?.street} error={errors?.address?.street} onChange={(event) => handleFormChange(event, 'address')} />

                    {errors?.address?.street && <Typography color={'error.base'}>{errors?.address?.street}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Huisnummer*</Typography>

                    <TextField name={'house_nr'} type={'text'} value={data?.address?.house_nr} error={errors?.address?.house_nr} onChange={(event) => handleFormChange(event, 'address')} />

                    {errors?.address?.house_nr && <Typography color={'error.base'}>{errors?.address?.house_nr}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Toevoeging</Typography>

                    <TextField name={'house_nr_addition'} type={'text'} value={data?.address?.house_nr_addition} error={errors?.address?.house_nr_addition} onChange={(event) => handleFormChange(event, 'address')} />

                    {errors?.address?.house_nr_addition && <Typography color={'error.base'}>{errors?.address?.house_nr_addition}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Postcode*</Typography>

                    <TextField name={'zip_code'} type={'text'} value={data?.address?.zip_code} error={errors?.address?.zip_code} onChange={(event) => handleFormChange(event, 'address')} />

                    {errors?.address?.zip_code && <Typography color={'error.base'}>{errors?.address?.zip_code}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Stad*</Typography>

                    <TextField name={'city'} type={'text'} value={data?.address?.city} error={errors?.address?.city} onChange={(event) => handleFormChange(event, 'address')} />

                    {errors?.address?.city && <Typography color={'error.base'}>{errors?.address?.city}</Typography>}
                </div>
            </Box>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default InvestorAddressForm;
