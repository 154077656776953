import Modal from '@/components/Modal';
import { BuildingStorefrontIcon, CalendarDaysIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import * as Formatter from '@/utils/FormatterUtil.js';
import Tooltip from '@/components/Tooltip';

const CollateralModal = ({ onClose, collateral }) => {
    return (
        <Modal width={840} onClose={onClose}>
            <img src={collateral.image} className={'aspect-[5/3] w-full rounded-xl object-cover'} />

            <div>
                <div className={'mt-10 flex items-center gap-2'}>
                    <h1 className={'text-3xl font-bold'}>{Formatter.currency(collateral.value)}</h1>

                    <Tooltip title={'Capilex Confirmed Value'}>
                        <div className={'flex gap-1'}>
                            <p className={'text-sm'}>CCV</p>

                            <InformationCircleIcon className={'aspect-square h-3'} />
                        </div>
                    </Tooltip>
                </div>

                <div className={'mt-6 grid grid-cols-2 gap-6'}>
                    <div className={'flex items-center gap-2'}>
                        <BuildingStorefrontIcon className={'aspect-square w-5'} />
                        <p>{collateral.build_type}</p>
                    </div>

                    <div className={'flex items-center gap-2'}>
                        <CalendarDaysIcon className={'aspect-square w-5'} />
                        <p>{collateral.build_year}</p>
                    </div>

                    {collateral?.volume !== undefined && (
                        <div>
                            <p className={'text-sm text-grey-900'}>Inhoud</p>

                            <p className={'mt-1'}>{collateral.volume} m3</p>
                        </div>
                    )}

                    {collateral?.use_surface !== undefined && (
                        <div>
                            <p className={'text-sm text-grey-900'}>Woonoppervlakte</p>

                            <p className={'mt-1'}>{collateral.use_surface} m2</p>
                        </div>
                    )}

                    {collateral?.parcel_surface !== undefined && (
                        <div>
                            <p className={'text-sm text-grey-900'}>Perceeloppervlakte</p>

                            <p className={'mt-1'}>{collateral.parcel_surface} m2</p>
                        </div>
                    )}

                    {collateral?.energy_label !== undefined && (
                        <div>
                            <p className={'text-sm text-grey-900'}>Energielabel</p>

                            <p className={'mt-1'}>{collateral.energy_label}</p>
                        </div>
                    )}
                </div>

                <div className={'mt-6'}>
                    {collateral.company_description ? (
                        <p>{collateral.company_description}</p>
                    ) : (
                        <>
                            <p className={'text-sm text-grey-900'}>De kredietnemer is actief in (onder meer) de navolgende branches/sectoren:</p>

                            <div className={'grid grid-cols-1 gap-1'}>{collateral.company_sbi ? collateral.company_sbi.map((sbi) => <p>{sbi.sbiOmschrijving}</p>) : <p>-</p>}</div>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default CollateralModal;
