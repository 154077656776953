import { createContext, useContext, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import ApplicationEditorModal from '@/components/modals/ApplicationEditorModal.jsx';
import CompanyEditorModal from '@/components/modals/CompanyEditorModal.jsx';
import CompanyContactEditorModal from '@/components/modals/CompanyContactEditorModal.jsx';
import InvestorEditorModal from '@/components/modals/InvestorEditorModal.jsx';
import CompanyContactDetailModal from '@/components/modals/CompanyContactDetailModal.jsx';
import UserEditorModal from '@/components/modals/UserEditorModal.jsx';
import InvestorBondEditorModal from '@/components/modals/InvestorBondEditorModal.jsx';
import DocumentEditorModal from '@/components/modals/DocumentEditorModal.jsx';
import InvestorAddressEditorModal from '@/components/modals/InvestorAddressEditorModal.jsx';
import ApplicationCollateralObjectEditorModal from '@/components/modals/ApplicationCollateralObjectEditorModal.jsx';
import EntityEditorModal from '@/components/modals/EntityEditorModal.jsx';
import TrancheEditorModal from '@/components/modals/TrancheEditorModal.jsx';
import LabelEditorModal from '@/components/modals/LabelEditorModal.jsx';
import CollateralModal from '@/components/modals/CollateralModal.jsx';
import ApplicationSourceEditorModal from '@/components/modals/ApplicationSourceEditorModal.jsx';
import CompanyUserEditorModal from '@/components/modals/CompanyUserEditorModal.jsx';

const Modals = {
    'application-editor': ApplicationEditorModal,
    'application-collateral-object-editor': ApplicationCollateralObjectEditorModal,
    'company-editor': CompanyEditorModal,
    'investor-editor': InvestorEditorModal,
    'investor-address-editor': InvestorAddressEditorModal,
    'investor-bond-editor': InvestorBondEditorModal,
    'user-editor': UserEditorModal,
    'company-user-editor': CompanyUserEditorModal,
    'source-editor': ApplicationSourceEditorModal,
    'address-editor': InvestorAddressEditorModal,
    'contact-editor': CompanyContactEditorModal,
    'contact-detail': CompanyContactDetailModal,
    'document-editor': DocumentEditorModal,
    'entity-editor': EntityEditorModal,
    'tranche-editor': TrancheEditorModal,
    'label-editor': LabelEditorModal,
    'collateral': CollateralModal,
};

export const ModalContext = createContext();

export const useModal = () => {
    const context = useContext(ModalContext);

    return context;
};

export const ModalProvider = ({ presets, children }) => {
    const [active, setActive] = useState([]);

    const openModal = (id, properties = {}) => {
        let newActive = [...active];

        newActive.push({ id, properties });
        setActive(newActive);
    };

    const closeModal = (index) => {
        let newActive = [...active];

        newActive.splice(index, 1);
        setActive(newActive);
    };

    const clearModals = () => {
        setActive([]);
    };

    return (
        <ModalContext.Provider value={{ openModal, closeModal, clearModals }}>
            <AnimatePresence>
                {active.map((modal, index) => {
                    const Modal = Modals[modal.id];

                    return <Modal key={`modal-${index}`} presets={presets} {...modal.properties} onClose={() => closeModal(index)} />;
                })}
            </AnimatePresence>

            {children}
        </ModalContext.Provider>
    );
};
