import Dialog from '@/components/Dialog';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Box from '@/components/Box';
import { ArrowPathRoundedSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const CompanyCreditReportFetchDialog = ({ onClose, company, presets }) => {
    const [processing, setProcessing] = useState(false);

    const handleCreditReportFetch = () => {
        setProcessing(true);
        router.post(
            route('companies.credit-reports.fetch', { company: company.id }),
            {},
            {
                onSuccess: () => onClose(),
                onFinish: () => setProcessing(false),
                preserveScroll: true,
            },
        );
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Kredietrapport opvragen
            </Typography>

            <Typography textAlign={'center'}>Weet je zeker dat je een nieuw kredietrapport wilt opvragen?</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleCreditReportFetch}>
                    <ArrowPathRoundedSquareIcon width={24} />
                    <span className={'font-bold'}>Kredietrapport opvragen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default CompanyCreditReportFetchDialog;
