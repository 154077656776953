import { createGlobalStyle } from 'styled-components';
import asset from '@/utils/VaporUtil.js';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Larsseit';
        src: url(${asset('assets/fonts/Larsseit-Light.woff2')});
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: 'Larsseit';
        src: url(${asset('assets/fonts/Larsseit.woff2')});
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Larsseit';
        src: url(${asset('assets/fonts/Larsseit-Medium.woff2')});
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Larsseit';
        src: url(${asset('assets/fonts/Larsseit-Bold.woff2')});
        font-weight: 800;
        font-style: normal;
    }

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        font-family: 'Larsseit', sans-serif;
    }

    html, body {
        scroll-behavior: smooth;
        font-size: 16px;
    }

    body {
        margin-bottom: 0px !important;
        background: ${({ theme: { colors } }) => colors.background.base};
    }

    a {
        color: unset;
        text-decoration: none;
    }

    ul {
      list-style-position: inside;
    }
`;

export default GlobalStyle;
