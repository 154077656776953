import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { XMarkIcon } from '@heroicons/react/24/outline';

const classNames = {
    sizes: {
        small: 'h-10 px-2 rounded-md',
        normal: 'h-14 px-4 rounded-lg',
    },
};

const Chip = ({ label, onRemove, size }) => (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className={`flex ${size === 'small' ? 'h-7 px-1' : 'h-9 px-2'} items-center gap-2 rounded-lg bg-[#F0F0F0] px-3`}>
        <span className={`whitespace-nowrap text-sm ${size === 'small' ? 'text-xs' : 'text-sm'}`}>{label}</span>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} onClick={onRemove} className={'flex items-center justify-center'}>
            <XMarkIcon className={'size-4'} />
        </motion.div>
    </motion.div>
);

const Dropdown = ({ isOpen, anchor, options, onClose, onSelect, renderTitle, renderDescription, focusedIndex, setFocusedIndex }) => {
    const dropdownRef = useRef(null);
    const optionsRef = useRef([]);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 'auto', maxHeight: 'auto' });

    const updatePosition = useCallback(() => {
        if (!anchor || !dropdownRef.current || !isOpen) return;

        const anchorRect = anchor.getBoundingClientRect();
        const dropdownHeight = dropdownRef.current.offsetHeight;

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        const spacing = 8; // 8px spacing between input and dropdown
        let top = anchorRect.bottom + scrollTop + spacing;
        let left = anchorRect.left + scrollLeft;

        const bottomSpaceAvailable = window.innerHeight - anchorRect.bottom - spacing;
        const topSpaceAvailable = anchorRect.top - spacing;

        let maxHeight = Math.min(320, window.innerHeight - 2 * spacing); // Maximum of 320px or screen height minus 2x spacing

        if (bottomSpaceAvailable < maxHeight && topSpaceAvailable > bottomSpaceAvailable) {
            // Position above if there's more space
            top = anchorRect.top - maxHeight - spacing + scrollTop;
            maxHeight = Math.min(maxHeight, topSpaceAvailable);
        } else {
            // Position below
            maxHeight = Math.min(maxHeight, bottomSpaceAvailable);
        }

        setDropdownPosition({
            top,
            left,
            width: anchorRect.width,
            maxHeight: `${maxHeight}px`,
        });
    }, [isOpen, anchor]);

    useEffect(() => {
        updatePosition();
        window.addEventListener('resize', updatePosition);
        window.addEventListener('scroll', updatePosition, true);
        return () => {
            window.removeEventListener('resize', updatePosition);
            window.removeEventListener('scroll', updatePosition, true);
        };
    }, [updatePosition]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target) && !anchor.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen, onClose, anchor]);

    useEffect(() => {
        if (isOpen && focusedIndex >= 0 && focusedIndex < options.length) {
            optionsRef.current[focusedIndex]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [isOpen, focusedIndex, options.length]);

    return ReactDOM.createPortal(
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    ref={dropdownRef}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.2 }}
                    className='absolute z-[1000] overflow-hidden rounded-md border border-grey-200 bg-white drop-shadow-lg'
                    style={{
                        top: dropdownPosition.top,
                        left: dropdownPosition.left,
                        width: dropdownPosition.width,
                        maxHeight: dropdownPosition.maxHeight,
                    }}
                >
                    <div role='listbox' className='max-h-full overflow-y-auto'>
                        <AnimatePresence mode='wait'>
                            {options.length > 0 ? (
                                <motion.div key='options' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
                                    {options.map((option, index) => (
                                        <motion.div
                                            key={option.value}
                                            ref={(el) => (optionsRef.current[index] = el)}
                                            className={`flex cursor-pointer items-center justify-between p-4 transition-colors duration-200
                                                ${index === focusedIndex ? 'bg-[#F8F8F8]' : 'hover:bg-[#F8F8F8]'}
                                            `}
                                            onClick={() => onSelect(option)}
                                            onMouseEnter={() => setFocusedIndex(index)}
                                            role='option'
                                            aria-selected={index === focusedIndex}
                                        >
                                            <div className='flex flex-col'>
                                                <p>{renderTitle ? renderTitle(option) : option.title}</p>
                                                {renderDescription && <p className='text-grey-600'>{renderDescription(option)}</p>}
                                            </div>
                                        </motion.div>
                                    ))}
                                </motion.div>
                            ) : (
                                <motion.div key='no-options' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className='flex h-20 items-center justify-center p-4 text-center text-grey-500'>
                                    Geen opties gevonden
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>,
        document.body,
    );
};

const Select = ({ name, options, value, onChange, multiple, className, disabled, renderTitle, renderDescription, placeholder, size = 'normal', ...properties }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [isFocused, setIsFocused] = useState(false);
    const selectRef = useRef(null);

    const selectedOptions = useMemo(() => {
        if (multiple) {
            return Array.isArray(value) ? (value || []).map((v) => options?.find((option) => option?.value === v || (v === null && option?.value === null))).filter(Boolean) : [];
        } else {
            return value !== undefined ? [options?.find((option) => option?.value === value || (value === null && option?.value === null))].filter(Boolean) : [];
        }
    }, [value, multiple, options]);

    const availableOptions = useMemo(() => {
        return (options || []).filter((option) => !selectedOptions.some((selectedOption) => selectedOption?.value === option?.value || (selectedOption?.value === null && option?.value === null)));
    }, [options, selectedOptions]);

    const handleOptionSelect = useCallback(
        (option) => {
            if (multiple) {
                const updatedValue = [...(value || []), option.value];
                onChange({ target: { name, value: updatedValue } });
            } else {
                onChange({ target: { name, value: option.value } });
                setDropdownOpen(false);
            }
            selectRef.current?.focus();
        },
        [onChange, name, multiple, value],
    );

    const handleOptionRemove = useCallback(
        (optionValue) => {
            if (multiple) {
                const updatedValue = Array.isArray(value) ? value.filter((v) => v !== optionValue && !(v === null && optionValue === null)) : [];
                onChange({ target: { name, value: updatedValue } });
            } else {
                onChange({ target: { name, value: null } });
            }
        },
        [onChange, name, multiple, value],
    );

    const handleDropdownToggle = useCallback(() => {
        if (!disabled) {
            setDropdownOpen((prev) => !prev);
            setIsFocused(true);
            setFocusedIndex(0);
        }
    }, [disabled]);

    const handleKeyboardNavigation = useCallback(
        (event) => {
            if (disabled) return;

            switch (event.key) {
                case 'ArrowDown':
                case 'ArrowUp':
                    event.preventDefault();
                    if (!isDropdownOpen) {
                        setDropdownOpen(true);
                        setFocusedIndex(0);
                    } else {
                        const step = event.key === 'ArrowDown' ? 1 : -1;
                        const newIndex = (focusedIndex + step + availableOptions.length) % availableOptions.length;
                        setFocusedIndex(newIndex);
                    }
                    break;
                case 'Enter':
                    event.preventDefault();
                    if (isDropdownOpen && focusedIndex !== -1 && availableOptions[focusedIndex]) {
                        handleOptionSelect(availableOptions[focusedIndex]);
                    } else {
                        setDropdownOpen(true);
                    }
                    break;
                case 'Escape':
                    setDropdownOpen(false);
                    break;
                case 'Tab':
                    if (isDropdownOpen) {
                        event.preventDefault();
                        const newIndex = (focusedIndex + 1) % availableOptions.length;
                        setFocusedIndex(newIndex);
                    }
                    break;
                default:
                    break;
            }
        },
        [disabled, isDropdownOpen, focusedIndex, availableOptions, handleOptionSelect],
    );

    const handleFocus = useCallback(() => {
        if (!disabled) {
            setIsFocused(true);
        }
    }, [disabled]);

    const handleBlur = useCallback(() => {
        setTimeout(() => {
            if (!selectRef.current?.contains(document.activeElement)) {
                setIsFocused(false);
            }
        }, 0);
    }, []);

    return (
        <>
            <div className={twMerge('relative w-full', className)} ref={selectRef} onKeyDown={handleKeyboardNavigation} tabIndex={disabled ? -1 : 0} onFocus={handleFocus} onBlur={handleBlur} {...properties}>
                <div
                    className={twMerge(
                        `${classNames.sizes[size]} text-black w-full bg-white border border-grey-200 focus:border-primary-500 focus:outline-none`,
                        'flex items-center transition-colors duration-200',
                        disabled ? 'bg-grey-50 cursor-not-allowed' : 'cursor-pointer',
                        isFocused && !disabled ? 'border-primary-500' : '',
                        className,
                    )}
                    onClick={handleDropdownToggle}
                >
                    <div className='relative grow overflow-hidden'>
                        <div className='no-scrollbar flex items-center gap-2 overflow-x-auto'>
                            <AnimatePresence mode='wait'>
                                {selectedOptions.length > 0 ? (
                                    selectedOptions.map((option) => <Chip key={option?.value === null ? 'null' : option?.value} size={size} label={renderTitle ? renderTitle(option) : option?.title} onRemove={() => handleOptionRemove(option?.value)} />)
                                ) : (
                                    <span className='text-grey-400'>{placeholder}</span>
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                    <div className='ml-2 shrink-0'>
                        <motion.svg className='aspect-square h-5 text-grey-300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' animate={{ rotate: isDropdownOpen ? 180 : 0 }} transition={{ duration: 0.2 }}>
                            <path fillRule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
                        </motion.svg>
                    </div>
                </div>
            </div>

            <Dropdown isOpen={isDropdownOpen} anchor={selectRef.current} options={availableOptions} onClose={() => setDropdownOpen(false)} onSelect={handleOptionSelect} renderTitle={renderTitle} renderDescription={renderDescription} focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex} />
        </>
    );
};

export default Select;
