import TextField from '../fields/TextField';
import Typography from '../Typography';
import Box from '../Box';
import SelectField from '@/components/fields/SelectField';
import EmailField from '@/components/fields/EmailField';
import { useMemo } from 'react';
import * as Formatter from '@/utils/FormatterUtil.js';
import PhoneField from '@/components/fields/PhoneField';
import AutocompleteField from '@/components/fields/AutocompleteField.jsx';

const InvestorForm = ({ form, onSubmit, presets, ...properties }) => {
    const options = {
        salutations: presets.salutations.map((salutation) => ({
            title: Formatter.salutation(salutation),
            value: salutation,
        })),
        investor_types: presets.investor_types.map((investor_type) => ({
            title: Formatter.investorType(investor_type),
            value: investor_type,
        })),
        countries: presets.countries.map((country) => ({
            title: country.name,
            value: country.id,
        })),
    };
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event, key = null) => {
        const target = event.target;
        const { name, value } = target;

        if (key) {
            form.setData({ ...data, [key]: { ...data?.[key], [name]: value } });
        } else {
            form.setData({ ...data, [name]: value });
        }
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Type*</Typography>

                    <SelectField name={'type'} value={data?.type} error={errors?.type} options={options.investor_types} onChange={handleFormChange} />

                    {errors?.type && <Typography color={'error.base'}>{errors.type}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Aanhef*</Typography>

                    <SelectField name={'salutation'} value={data?.user?.salutation} error={errors?.user?.salutation} options={options.salutations} onChange={(event) => handleFormChange(event, 'user')} />

                    {errors?.user?.salutation && <Typography color={'error.base'}>{errors.user.salutation}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Voornaam*</Typography>

                    <TextField name={'first_name'} type={'text'} value={data?.user?.first_name} error={errors?.user?.first_name} onChange={(event) => handleFormChange(event, 'user')} />

                    {errors?.user?.first_name && <Typography color={'error.base'}>{errors.user.first_name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Achternaam*</Typography>

                    <TextField name={'last_name'} type={'text'} value={data?.user?.last_name} error={errors?.user?.last_name} onChange={(event) => handleFormChange(event, 'user')} />

                    {errors?.user?.last_name && <Typography color={'error.base'}>{errors.user.last_name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>E-mailadres*</Typography>

                    <EmailField name={'email'} value={data?.user?.email} error={errors?.user?.email} onChange={(event) => handleFormChange(event, 'user')} />

                    {errors?.user?.email && <Typography color={'error.base'}>{errors.user.email}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Telefoonnummer*</Typography>

                    <PhoneField name={'phone'} value={data?.user?.phone} error={errors?.user?.phone} onChange={(event) => handleFormChange(event, 'user')} />

                    {errors?.user?.phone && <Typography color={'error.base'}>{errors.user.phone}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Geboortedatum</Typography>

                    <TextField name={'date_of_birth'} value={data?.user?.date_of_birth} error={errors?.user?.date_of_birth} type={'date'} onChange={(event) => handleFormChange(event, 'user')} />

                    {errors?.user?.date_of_birth !== undefined && <Typography color={'error.base'}>{errors.user.date_of_birth}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Nationaliteit</Typography>

                    <SelectField name={'country_id'} value={data?.investor?.country_id} error={errors?.investor?.country_id} options={options.countries} onChange={(event) => handleFormChange(event, 'investor')} />

                    {errors?.investor?.country_id !== undefined && <Typography color={'error.base'}>{errors.investor.country_id}</Typography>}
                </div>

                {data?.type === 'company' && (
                    <div className={'flex flex-col gap-2'}>
                        <Typography>Bedrijf</Typography>

                        <AutocompleteField name={'company'} value={data?.company} model={'Company'} onChange={handleFormChange} renderTitle={(option) => option.name} />

                        {errors?.company?.id && <Typography color={'error.base'}>{errors.company?.id}</Typography>}
                    </div>
                )}

                <div className={'flex flex-col gap-2'}>
                    <Typography>IBAN</Typography>

                    <TextField name={'iban'} value={data?.investor?.iban} error={errors?.investor?.iban} onChange={(event) => handleFormChange(event, 'investor')} />

                    {errors?.investor?.iban && <Typography color={'error.base'}>{errors.investor.iban}</Typography>}
                </div>
            </Box>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default InvestorForm;
