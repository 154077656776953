import Dialog from '@/components/Dialog';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Box from '@/components/Box';
import { ArrowLeftOnRectangleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { router } from '@inertiajs/react';
import { useState } from 'react';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';

const LogoutDialog = ({ onClose }) => {
    const [processing, setProcessing] = useState(false);

    const handleLogout = () => {
        router.post(route('logout'), null, {
            onFinish: () => onClose(),
            preserveScroll: true,
        });
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Uitloggen
            </Typography>

            <Typography textAlign={'center'}>Klik hieronder op "Uitloggen" om uit te loggen.</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleLogout}>
                    <ArrowLeftOnRectangleIcon width={24} />
                    <span className={'font-bold'}>Uitloggen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default LogoutDialog;
