import Dialog from '@/components/Dialog';
import Typography from '@/components/Typography';

const WarningDialog = ({ title, description, ...properties }) => (
    <Dialog {...properties}>
        <Typography>{title}</Typography>

        <Typography>{description}</Typography>
    </Dialog>
);

export default WarningDialog;
