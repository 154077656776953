import Modal from '@/components/Modal';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import { XCircleIcon } from '@heroicons/react/24/outline';

const CompanyContactDetailModal = ({ onClose, contact, presets }) => (
    <Modal width={840} onClose={onClose}>
        <Typography className={'text-xl font-bold'}>Contactpersoon</Typography>

        <Typography>Occaecat veniam esse esse reprehenderit Lorem anim nulla aliqua ut sit laborum deserunt et elit eu.</Typography>

        <Button className={'mt-5'} onClick={onClose} backgroundColor={'grey.1'}>
            <XCircleIcon width={24} />
            <span className={'font-bold'}>Annuleren</span>
        </Button>
    </Modal>
);

export default CompanyContactDetailModal;
