import { twMerge } from 'tailwind-merge';

const Label = ({ children, className, ...properties }) => {
    return (
        <label className={twMerge('font-semibold', className)} {...properties}>
            {children}
        </label>
    );
};

export default Label;
