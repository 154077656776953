import Modal from '@/components/Modal';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import { useForm } from '@inertiajs/react';
import Box from '@/components/Box';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import InvestorBondForm from '@/components/forms/InvestorBondForm';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';

const InvestorBondEditorModal = ({ onClose, presets, investor, bond }) => {
    const defaultValues = { ...bond };
    const form = useForm(defaultValues);

    const handleApplicationEditor = (event) => {
        event.preventDefault();
        if (bond?.id) {
            form.submit(
                'put',
                route('investors.investor.bond.update', {
                    investor: investor.id,
                    bond: bond.id,
                }),
                {
                    onSuccess: () => onClose(),
                    preserveScroll: true,
                },
            );
        } else {
            form.submit('post', route('investors.investor.bond.store', { investor: investor.id }), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        }
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Obligatie {bond ? 'wijzigen' : 'toevoegen'}</Typography>

            <Typography>Vul onderstaand de gegevens van een obligatie in.</Typography>

            <InvestorBondForm className={'mt-5'} form={form} presets={presets} onSubmit={handleApplicationEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handleApplicationEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Obligatie {bond ? 'wijzigen' : 'toevoegen'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default InvestorBondEditorModal;
