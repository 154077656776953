import Dialog from '@/components/Dialog';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import { XCircleIcon } from '@heroicons/react/24/outline';

const SuccessDialog = ({ title, description, onClose, ...properties }) => (
    <Dialog className='flex flex-col items-center' onClose={onClose} {...properties}>
        <Typography className={'text-xl font-bold'} textAlign={'center'}>
            {title}
        </Typography>

        <Typography mt={16} textAlign={'center'}>
            {description}
        </Typography>

        <Button className={'mx-auto mt-5 bg-grey-150'} onClick={onClose}>
            <XCircleIcon width={24} />
            <span className={'font-bold'}>Sluiten</span>
        </Button>
    </Dialog>
);

export default SuccessDialog;
